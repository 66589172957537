<template>
  <div class="faq">
    <header class="page-header">
      <div class="container">
        <div class="page-header-flex-wrap no-margin-bottom">
          <h1 class="page-title section-header">{{ $t("f_a_q") }}</h1>
        </div>
      </div>
    </header>
    <section class="section medium">
      <div class="container">
        <div class="container-small">
          <div class="faq-wrapper full-width">

            <div class="faq-dropdown w-dropdown" v-for="(item, index) in list_faq" :key="index" :item="item">
              <a v-b-toggle :href="'#faq-'+index" @click.prevent class="faq-dropdown-toggle full-width w-dropdown-toggle">
                <div class="faq-icon-block">
                  <div class="faq-icon-line"></div>
                  <div class="faq-icon-line rotate"></div>
                </div>
                <div class="faq-title large">{{item.title}}</div>
              </a>
              <b-collapse :id="'faq-' + index" class="faq-dropdown-list w-dropdown-list">
                <p class="privacy" v-html="item.desc[0]"></p>
              </b-collapse>
            </div>

            <!--
            <div class="faq-dropdown w-dropdown">
              <div v-b-toggle.faq-2 class="faq-dropdown-toggle full-width w-dropdown-toggle">
                <div class="faq-icon-block">
                  <div class="faq-icon-line"></div>
                  <div class="faq-icon-line rotate"></div>
                </div>
                <div class="faq-title large">Is it possible to cancel my subscription?</div>
              </div>
              <b-collapse id="faq-2" class="faq-dropdown-list w-dropdown-list">
                <p class="faq-paragraph">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse varius
                  enim in eros elementum tristique. Duis cursus, mi quis viverra ornare, eros dolor interdum nulla, ut
                  commodo diam libero vitae erat. Aenean faucibus nibh et justo cursus id rutrum lorem imperdiet. Nunc
                  ut sem vitae risus tristique posuere.</p>
              </b-collapse>
            </div>
            <div class="faq-dropdown w-dropdown">
              <div v-b-toggle.faq-3 class="faq-dropdown-toggle full-width w-dropdown-toggle">
                <div class="faq-icon-block">
                  <div class="faq-icon-line"></div>
                  <div class="faq-icon-line rotate"></div>
                </div>
                <div class="faq-title large">Can I recover my pricing plan after cancelling it?</div>
              </div>
              <b-collapse id="faq-3" class="faq-dropdown-list w-dropdown-list">
                <p class="faq-paragraph">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse varius
                  enim in eros elementum tristique. Duis cursus, mi quis viverra ornare, eros dolor interdum nulla, ut
                  commodo diam libero vitae erat. Aenean faucibus nibh et justo cursus id rutrum lorem imperdiet. Nunc
                  ut sem vitae risus tristique posuere.</p>
              </b-collapse>
            </div>
            <div class="faq-dropdown w-dropdown">
              <div v-b-toggle.faq-4 class="faq-dropdown-toggle full-width w-dropdown-toggle">
                <div class="faq-icon-block">
                  <div class="faq-icon-line"></div>
                  <div class="faq-icon-line rotate"></div>
                </div>
                <div class="faq-title large">How can SoftBit help my business?</div>
              </div>
              <b-collapse id="faq-4" class="faq-dropdown-list w-dropdown-list">
                <p class="faq-paragraph">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse varius
                  enim in eros elementum tristique. Duis cursus, mi quis viverra ornare, eros dolor interdum nulla, ut
                  commodo diam libero vitae erat. Aenean faucibus nibh et justo cursus id rutrum lorem imperdiet. Nunc
                  ut sem vitae risus tristique posuere.</p>
              </b-collapse>
            </div>
            <div class="faq-dropdown w-dropdown">
              <div v-b-toggle.faq-5 class="faq-dropdown-toggle full-width w-dropdown-toggle">
                <div class="faq-icon-block">
                  <div class="faq-icon-line"></div>
                  <div class="faq-icon-line rotate"></div>
                </div>
                <div class="faq-title large">Which pricing plans do you offer and what&#x27;s included?</div>
              </div>
              <b-collapse id="faq-5" class="faq-dropdown-list w-dropdown-list">
                <p class="faq-paragraph">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse varius
                  enim in eros elementum tristique. Duis cursus, mi quis viverra ornare, eros dolor interdum nulla, ut
                  commodo diam libero vitae erat. Aenean faucibus nibh et justo cursus id rutrum lorem imperdiet. Nunc
                  ut sem vitae risus tristique posuere.</p>
              </b-collapse>
            </div>
            -->
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import axios from "axios";
import config from "@/store/config";

export default {
  name: 'Faq',
  data() {
    return {
      list_faq: {}
    }
  },
  mounted() {
    this.getListFaq();
    //this.changeLocale(this.$cookie.get('elza_lng'));
    //this.setLanguage(this.$cookie.get('elza_lng'));
  },
  methods: {
    getListFaq: function () {
      axios({
        method: "POST",
        url: config.getters.mainUrl + "service/list_faq?ts=" + Math.round(+new Date() / 1000),
        data: {
          language: this.$cookie.get('elza_lng')
        },
        headers: {
          'Cache-Control': 'no-cache',
          'Pragma': 'no-cache',
          'Expires': '0',
        }
      }).then(result => {

        if (result != undefined && result.data != undefined && result.data.result != undefined) {


          if (result.data.result == true) {
            this.list_faq = result.data.data;
          }
        }
      }, error => {
        console.log(error);
      })
    }
  }
}
</script>

<style lang=scss>
@import '../assets/scss/base/variables';

.faq-wrapper {
  width: 50%;
}

.faq-wrapper.full-width {
  width: 100%;
  margin-top: 48px;
}

.faq-dropdown {
  display: block;
  overflow: hidden;
  width: 100%;
  margin-bottom: 10px;
  border-radius: 8px;
  background-color: $gray-800;
  transition: transform 300ms ease;
}

.faq-dropdown:hover {
  transform: translate(0px, -2px);
}

.faq-dropdown-toggle {
  display: flex;
  padding: 12px;
  align-items: center;
  font-family: 'Space Grotesk', sans-serif;
  color: #fff;
  font-size: 16px;
  line-height: 22px;
  font-weight: 500;
}

.faq-dropdown-toggle.full-width {
  padding: 16px;
}

.faq-icon-block {
  position: relative;
  display: flex;
  width: 32px;
  height: 32px;
  margin-right: 16px;
  justify-content: center;
  align-items: center;
  flex: 0 0 auto;
  border-radius: 4px;
  background-color: $gray-700;
}

.faq-flex-wrapper {
  display: flex;
  align-items: flex-start;
}

.faq-dropdown-list {
  position: static;
  background-color: $gray-800;
  transition: $transition-base;
}

.faq-dropdown-list.w--open {
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
}

.faq-icon-line {
  width: 16px;
  height: 2px;
  border-radius: 4px;
  background-color: #fff;
  transition: $transition-base;
}

.faq-icon-line.rotate {
  position: absolute;
  transform: rotate(90deg);
}

.collapsed {
  .faq-icon-line {
    &:first-of-type {
      transform: translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(
              0deg
      ) rotateY(
              0deg
      ) rotateZ(
              0deg
      ) skew(
              0deg,
              0deg
      );
      transform-style: preserve-3d;
    }

    &:last-of-type {
      transform: translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(
              0deg
      ) rotateY(
              0deg
      ) rotateZ(
              90deg
      ) skew(
              0deg,
              0deg
      );
      transform-style: preserve-3d;
    }
  }
}

.not-collapsed {
  .faq-icon-line {
    &:first-of-type {
      transform: translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(
              0deg
      ) rotateY(
              0deg
      ) rotateZ(
              180deg
      ) skew(
              0deg,
              0deg
      );
      transform-style: preserve-3d;
    }

    &:last-of-type {
      transform: translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(
              0deg
      ) rotateY(
              0deg
      ) rotateZ(
              180deg
      ) skew(
              0deg,
              0deg
      );
      transform-style: preserve-3d;
    }
  }
}

.faq-paragraph {
  margin-bottom: 0;
  padding-right: 24px;
  padding-bottom: 24px;
  padding-left: 64px;
}

.faq-title {
  max-width: 100%;
  white-space: normal;
}

.faq-title.large {
  font-size: 18px;
  line-height: 24px;
}

@media screen and (max-width: 767px) {
  .faq-wrapper {
    width: 100%;
  }
  .faq-dropdown-toggle.full-width {
    padding: 12px;
  }
  .faq-flex-wrapper {
    flex-direction: column;
  }
  .faq-title.large {
    font-size: 16px;
    line-height: 22px;
  }
}
</style>
